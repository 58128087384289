export type ThemeType = typeof lightTheme;

export const lightTheme = {
  id: 1,
  name: 'Light',

  fontFamily: 'DM Sans',

  palette: {
    primary: '#007CF1',
    secondary: '#343956',
    green: '#44A901',
    orange: '#FFAA37',
    red: '#FF4B5A',

    text: {
      weak: '#A3A9C2',
      fair: '#525666',
      medium: '#222533', // default
      onPrimary: '#fff',
      onSecondary: '#ffffffe6'
    },

    backgrounds: {
      background: '#f9fcff',
      surface: '#fff',
      surfaceStrong: '#eef7ff',
      climateControl: '#edefff',
      info: '#F5F5F5'
    },

    borders: {
      weak: '#EEF2FC',
      medium: '#E2E6F5', // default
      strong: '#bdc1cf'
    },

    shadows: {
      extraLight: '#00000026',
      light: '#0000004D',
      medium: '#ddecfa',
      strong: '#c1c1c1'
    },

    buttons: {
      background: '#EEF2FC',
      hoverBackground: '#E2E6F5'
    },

    charts: {
      tooltipText: '#fff',
      tooltipBackground: 'rgba(96, 99, 109, 0.95)',
      gridLineColor: '#E2E6F5',
      gridLineColorStrong: '#bdc1cf',
      legend: {
        evenItem: '#f5f7ff',
        unevenItem: '#e9eefd'
      }
    },

    forms: {
      label: {
        color: '#626777'
      },
      input: {
        border: '#EBEDF8',
        background: 'transparent',
        placeholderText: '#A3A9C2',
        disabledText: '#222533b0',
        disabledBorder: '#e6e7ed66',
        disabledBackground: '#f8f8fd',
        disabledDarkBackground: '#E9E9F8',
        shadow: '#e3e8fb',
        boxShadow: '0px 5px 2px -4px #e3e8fb',
        focusShadow: '#cbd2ef'
      },
      switch: {
        innerShadow: '#b6bcd5',
        background: {
          checked: '#007CF1',
          unchecked: '#e2e6f5',
          disabled: '#93B6F4'
        },
        dotShadow: {
          checked: '#393b44',
          unchecked: '#cbd2ef'
        }
      },
      select: {
        label: {
          shadow: '#ddecfa'
        }
      }
    },

    tables: {
      titleRow: '#E2E6F5',
      evenRow: '#fff',
      unevenRow: '#fafafc',
      backgroundHighlight: '#f1f1ff80'
    },

    map: {
      road: '#FFFFFF',
      transit: '#f2f5f8',
      landscape: '#e9eef3',
      water: '#d8e4f0'
    },

    scrollbar: {
      track: 'transparent',
      thumb: '#bbc0d4'
    },

    sidebar: {
      collapseHandleShadow: '#cacedb',
      expandHandleShadow: '#0c0c0c17'
    },

    navbar: {
      link: '#72778b',
      selectedLink: '#222533'
    },

    topbar: {
      iconHoverBackground: '#F2F5F8'
    },

    cascader: {
      checkboxBorder: '#bcc1d5',
      checkboxBackground: '#fff'
    },

    select: {
      hover: '#f1f4ff'
    },

    popover: {
      background: '#fff',
      shadow: '#d4e2ef'
    },

    dropdown: {
      shadowWeak: 'rgba(0, 0, 0, 0.08)',
      shadowStrong: 'rgba(0, 0, 0, 0.12)'
    },

    tooltip: {
      background: {
        primary: '#343956',
        secondary: '#628AD0'
      },
      shadow: '#cdcdcd',
      textColor: '#fff'
    },

    esgReport: {
      chartColor: '#007CF1',
      cardShadow: '#ddecfa',
      energyWidgetFooter: '#EEF2FC',
      metricMeterBackground: '#dbe0ed'
    },

    printStyles: {
      chartColor: '#0099f1',
      text: {
        medium: '#3f4753',
        fair: '#5d6777',
        weak: '#cad0df'
      },
      borders: {
        medium: '#EEF2FC'
      }
    },

    stickyNotes: {
      text: '#222533',
      background: '#e6f3ff',
      backgroundLight: '#f0f8ff',
      backgroundHeader: '#bed6ed',
      shadow1: '#d7e3ef',
      shadow2: '#ddecfa'
    },

    climateControlHeatmap: {
      green: '#44A901',
      red: '#ff3434',
      orange: '#FF8E0A',
      grey: '#D9D9D9'
    },

    seasonalityTable: {
      itemBackground: 'transparent'
    },

    siteOverview: {
      alerts: {
        urgent: '#E7091B',
        important: '#FF5511',
      },
      utilities: {
        green: '#44A901',
        red: '#E7091B'
      },
      charts: {
        primary: '#00a6ff',
        secondary: '#e1e2e6',
        lines: '#E2E6F5',
      }
    },

    systemMessage: {
      error: '#E7091B',
      warning: '#F51',
      information: '#4967F9',
      success: '#3D9800',
      xmark: '#808494'
    }
  },

  text: {
    primary: '#000814',
    secondary: '#65687e',
    contrast: '#ffffff',
    disabled: '#9699a7',
    light: '#A3A9C2',
    white: '#ffffff'
  },

  primary: {
    main: '#276eea',
    dark: '#1b4da3',
    light: '#528bee',
    contrast: '#ffffff',
    hover: '#eef4fe',
    selected: '#d4e2fb',
    focus: '#b9d0f8',
    outlinedBorder: '#92b6f4',
    background: '#e8ecfb'
  },

  action: {
    active: '#72768a',
    hover: '#f5f5f7',
    selected: '#ebecef',
    disabledBackground: '#c9d0dd',
    focus: '#e0e1e6',
    disabled: '#7d8189',
    outlineBorder: '#ebedf8',
    divider: '#e0e1e6',
    filled: '#f7f7fc'
  },

  complimentary: {
    yellow: {
      main: '#FFB500',
      dark: '#FF9300',
      light: '#FFCD29'
    },
    orange: {
      main: '#FF821B',
      dark: '#E36600',
      light: '#FFC42E'
    },
    blue: {
      main: '#1B98FF',
      dark: '#1A78DD',
      light: '#63B7FF'
    },
    purple: {
      main: '#863EFF',
      dark: '#5D2BB2',
      light: '#9E64FF'
    },
    green: {
      main: '#00C851',
      dark: '#00AD1F',
      light: '#8FDF9C'
    }
  },

  background: {
    container: '#ffffff'
  },

  shadow: {
    primary: '#ddecfa',
    tenantLogo: 'rgba(0, 0, 0, 0.10)'
  },

  success: {
    main: '#44a901',
    dark: '#007300',
    light: '#6dce1c',
    contrast: '#ffffff',
    hover: '#f0f9eb',
    selected: '#daeecc',
    focus: '#c3e3ad',
    outlinedBorder: '#a1d37f',
    background: '#e9f5e1'
  },

  info: {
    main: '#4967F9'
  },

  warning: {
    main: '#F51',
    outlinedBorder: 'rgba(255, 85, 17, 0.50)'
  },

  error: {
    main: '#E7091B'
  },

  previewBanner: {
    background: '#fff',
    disabledButtonText: '#7d8189',
    disabledButtonBackground: '#c9d0dd'
  },

  widget: {
    green: '#00C851',
    red: '#EB3A48',
    blue: '#63B7FF'
  },

  skeleton: {
    background: '#040A2E1F'
  }
};